import React, { Component } from "react";

class ViewBrowseListings extends Component {
  render() {
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="viewBrowse"
        >
          <div className="container">
            <iframe
              src="//my.flexmls.com/tucsonassociationofrealtors/search/idx_links/20100422000941055860000000/listings"
              width="1080"
              height="1000"
              frameborder="0"
            ></iframe>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ViewBrowseListings;
