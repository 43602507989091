import React, { Component } from "react";
import HandsImage from "../assets/images/hands_keys.jpeg";

class BrowseListings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.openPageBrowseListing = this.openPageBrowseListing.bind(this);
  }

  openPageBrowseListing() {
    let namePage = "index-eight";

    if (this.props.idAgent) {
      namePage = window.SUBDOMAIN[1] + "/browse-listing-agent";
    }
    
    window.location.href = namePage;
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="browseListing"
        >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                    We are the key to your new home!
                  </h3>

                  <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                    Established in Arizona since 2016, CASAS Real Estate entrust
                    agents with the resources to help you get the perfect home
                    at the best price.
                  </p>

                  <div className="relative mt-10">
                    <a
                      //onClick={this.openPageBrowseListing}
                      className="btn hover:bg-strong-gray hover:border-orange-700 text-white rounded-md"
                      style={{
                        backgroundColor: "#e1dd20",
                        border: "#e1dd20",
                        cursor: "pointer",
                      }}
                    >
                      Browse Listings
                    </a>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-5">
                <div className="relative">
                  <img
                    src={HandsImage}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default BrowseListings;
