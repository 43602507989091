import React, { Component } from "react";
import { Link } from "react-router-dom";

import LogoLight from "../assets/images/logo_casas.jpeg";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { arrayServiceSocial } = this.props;
    return (
      <React.Fragment>
        {/*  Start Footer  */}
        <footer
          className="py-8 dark:bg-gray-900"
          style={{ backgroundColor: "#e1dd20" }}
        >
          <div className="container">
            <div className="grid md:grid-cols-12 items-center">
              <div className="md:col-span-3">
                <Link to="#" className="logo-footer">
                  <img
                    src={LogoLight}
                    className="md:ms-0 mx-auto"
                    alt=""
                    width="130"
                    height="130"
                  />
                </Link>
              </div>

              <div className="md:col-span-5 md:mt-0 mt-8">
                <div className="text-center">
                  <p>
                    Copyright © {new Date().getFullYear()} CASAS Real Estate{" "}
                  </p>
                </div>
              </div>

              <div className="md:col-span-4 md:mt-0 mt-8">
                <ul className="list-none foot-icon ltr:md:text-right rtl:md:text-left text-center">
                  {arrayServiceSocial &&
                    arrayServiceSocial.map((social) => (
                      <>
                        {social.serviceSocialWebSite === "facebook" && (
                          <li className="inline ms-1">
                            <Link
                              to={social.urlWebSite}
                              target="_blank"
                              style={{
                                backgroundColor: "#f2f1f1",
                                borderColor: "#5d5d5d",
                              }}
                              className="btn btn-icon btn-sm border rounded-md dark:border-slate-800 hover:bg-yellow-700 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-yellow-700 text-gray-400 hover:text-white"
                            >
                              <i
                                className="uil uil-facebook-f align-middle"
                                title="facebook"
                              ></i>
                            </Link>
                          </li>
                        )}

                        {social.serviceSocialWebSite === "twitter" && (
                          <li className="inline ms-1">
                            <Link
                              to={social.urlWebSite}
                              target="_blank"
                              style={{
                                backgroundColor: "#f2f1f1",
                                borderColor: "#5d5d5d",
                              }}
                              className="btn btn-icon btn-sm border rounded-md dark:border-slate-800 hover:bg-yellow-700 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-yellow-700 text-gray-400 hover:text-white"
                            >
                              <i
                                className="uil uil-twitter align-middle"
                                title="twitter"
                              ></i>
                            </Link>
                          </li>
                        )}

                        {social.serviceSocialWebSite === "linkedIn" && (
                          <li className="inline ms-1">
                            <Link
                              to={social.urlWebSite}
                              target="_blank"
                              style={{
                                backgroundColor: "#f2f1f1",
                                borderColor: "#5d5d5d",
                              }}
                              className="btn btn-icon btn-sm border rounded-md dark:border-slate-800 hover:bg-yellow-700 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-yellow-700 text-gray-400 hover:text-white"
                            >
                              <i
                                className="uil uil-linkedin align-middle"
                                title="linkedin"
                              ></i>
                            </Link>
                          </li>
                        )}

                        {social.serviceSocialWebSite === "instagram" && (
                          <li className="inline ms-1">
                            <Link
                              to={social.urlWebSite}
                              target="_blank"
                              style={{
                                backgroundColor: "#f2f1f1",
                                borderColor: "#5d5d5d",
                              }}
                              className="btn btn-icon btn-sm border rounded-md dark:border-slate-800 hover:bg-yellow-700 dark:bg-gray-900 hover:bg-orange-600 dark:hover:bg-yellow-700 text-gray-400 hover:text-white"
                            >
                              <i
                                className="uil uil-instagram align-middle"
                                title="instagram"
                              ></i>
                            </Link>
                          </li>
                        )}
                      </>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- End Footer --> */}

        {/* <!-- Back to top --> */}
        <Link
          to="home"
          id="back-to-top"
          className="back-to-top fixed hidden text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-orange-600 text-white leading-9"
        >
          <i className="uil uil-arrow-up"></i>
        </Link>
        {/* <!-- Back to top --> */}
      </React.Fragment>
    );
  }
}

export default Footer;
