const Airtable = require("airtable");

const airtableBase = new Airtable({
  apiKey:
    "patAJZ9KDqz4gOg8a.d5e15edf2e244c651c27815d3d96cfde1eae543001067bf9218d11cce9c5a1bf",
}).base("appP7bzI3kDGW7I5O");

async function getRecordsTable(tableName) {
  try {
    const records = await airtableBase(tableName).select().all();

    const result = records.map((record) => {
      const { fields, id } = record;

      if (fields["IsActive?"] == "True") {
        return {
          id: id,
          ...fields,
        };
      }
    });
    console.log({result})
    return result;
  } catch (error) {
    console.error("Error al obtener registros de Airtable:", error);
    throw error;
  }
}

export default getRecordsTable;
