import React, { Component } from "react";
import TinySlider from "tiny-slider-react";

import avatar1 from "../assets/images/client/marcela_fuentes.jpg";
import avatar2 from "../assets/images/client/maria_villarreal.jpg";
import avatar3 from "../assets/images/client/roberto_moreno.jpg";
import avatar4 from "../assets/images/client/rosio_moreno.jpg";
import avatar5 from "../assets/images/client/adam_reyes.jpg";
import avatar6 from "../assets/images/client/cecilia_arosemena.jpg";
import avatar7 from "../assets/images/client/ileny_guevara.jpg";
import avatar8 from "../assets/images/client/jocelyn_gonzvar.jpg";
import avatar9 from "../assets/images/client/jose_pablo_carpena.jpg";

import "tiny-slider/dist/tiny-slider.css";

const settings = {
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  controlsText: [
    '<i class="mdi mdi-chevron-left "></i>',
    '<i class="mdi mdi-chevron-right"></i>',
  ],
  gutter: 0,
  responsive: {
    768: {
      items: 2,
    },
  },
};

class Ourteam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [
        {
          id: "1",
          profile: avatar1,
          name: "Marcela Fuentes",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "2",
          profile: avatar2,
          name: "Maria Villarreal",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "3",
          profile: avatar3,
          name: "Roberto Moreno",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "4",
          profile: avatar4,
          name: "Rosio Moreno",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "5",
          profile: avatar5,
          name: "Adam Reyes",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "6",
          profile: avatar6,
          name: "Cecilia Arosemena",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "7",
          profile: avatar7,
          name: "Ileny Guevara",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "8",
          profile: avatar8,
          name: "Jocelyn Gonzvar",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "9",
          profile: avatar9,
          name: "Jose Pablo Carpena",
          designation: "JOB POSITION",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
      ],
    };
  }

  render() {
    const { rows, count } = this.props;
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="ourTeam"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6
                className="text-base font-medium uppercase mb-2"
                style={{ color: "#e1dd20" }}
              >
                OUR TEAM
              </h6>

              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Creative Minds
              </h3>
            </div>

            <div className="grid grid-cols-1 mt-8 relative">
              <div className="tiny-two-item">
                {count > 0 && (
                  <TinySlider settings={settings}>
                    {count > 0 &&
                      rows.map((website, index) => (
                        <div className="tiny-slide" key={index ? index : 0}>
                          <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                            <img
                              className="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                              src={website.avatars[0]?.downloadUrl}
                              alt=""
                              width="384"
                              height="512"
                            />
                            <div className="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                              <p className="text-base text-slate-500 dark:text-slate-200 line-clamp-4">
                                {website.aboutMe}
                              </p>

                              <div>
                                <a href={website.website} target="_blank">
                                  <span
                                    className="block mb-1"
                                    style={{ color: "#e1dd20" }}
                                  >
                                    {website.getAgent.fullName}
                                  </span>
                                </a>
                                <span className="text-slate-400 text-sm dark:text-white/60 block">
                                  {website.designation}
                                </span>

                                <span class="text-slate-400 text-sm dark:text-white/60 block">
                                  {website.jobPosition}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </TinySlider>
                )}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Ourteam;
