import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo_casas.jpeg";
import LogoDark from "../assets/images/logo_casas.jpeg";

import "../../node_modules/react-modal-video/scss/modal-video.scss";

import BackgroudImage from "../assets/images/casasre_bg2.jpeg";

import FooterAgent from "../component/FooterAgent";
import Switcher from "../component/Switcher";
import ViewBrowseListings from "../component/ViewBrowseListings";

class browseListingAgent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.windowScroll);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }
  toggleMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  navegationPage() {
    window.location.href = "/";
  }

  render() {
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";
    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a className="navbar-brand">
                <span className="inline-block dark:hidden">
                  <img
                    src={LogoDark}
                    className="l-dark"
                    alt=""
                    width="110"
                    height="110"
                  />
                  <img
                    src={LogoLight}
                    className="l-light"
                    alt=""
                    width="110"
                    height="110"
                  />
                </span>
                <img
                  src={LogoLight}
                  className="hidden dark:inline-block"
                  alt=""
                  width="110"
                  height="110"
                />
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  <li className="inline ms-1">
                    {JSON.parse(localStorage.getItem("serviceSocial")) ?
                      JSON.parse(localStorage.getItem("serviceSocial")).map(
                        (social) => (
                          <>
                            {social.serviceSocialWebSite === "facebook" && (
                              <>
                                <Link2 to={social.urlWebSite} target="_blank">
                                  <span className="login-btn-primary">
                                    <span
                                      style={{
                                        backgroundColor: "#5d5d5d",
                                        borderColor: "#5d5d5d",
                                      }}
                                      className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                                    >
                                      <i
                                        className="uil uil-facebook-f align-middle"
                                        title="facebook"
                                      ></i>
                                    </span>
                                  </span>
                                  <span className="login-btn-light">
                                    <span
                                      style={{
                                        backgroundColor: "#f2f1f1",
                                        borderColor: "#f2f1f1",
                                      }}
                                      className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                                    >
                                      <i
                                        className="uil uil-facebook-f align-middle"
                                        title="facebook"
                                      ></i>
                                    </span>
                                  </span>
                                </Link2>
                              </>
                            )}
                          </>
                        )
                      ): ''}
                  </li>
                </ul>
                <button
                  data-collapse="menu-collapse"
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`${toggleClass} navigation lg_992:order-1 lg_992:flex`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  <Link
                    className="nav-item"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">About Us</span>
                  </Link>
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Our Team</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="blog"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Browse Listing</span>
                  </Link>
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Testimonial</span>
                  </Link>
                  <Link
                    className="nav-item"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Blog</span>
                  </Link>

                  <Link
                    className="nav-item"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Contact Us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Property Management</span>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>

          <section
            style={{ backgroundImage: `url(${BackgroudImage})` }}
            className="py-36 md:py-72 w-full table relative bg-center bg-cover jarallax"
            data-jarallax
            data-speed="0.5"
            id="browseListingAgent"
          >
            <div className="absolute inset-0 bg-black opacity-70"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 text-center">
                <div className="lg:col-span-7 md:col-span-6 mt-14 md:mt-0">
                  <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                    Browse Listings
                  </h4>

                  <p className="text-white opacity-50 mb-0 max-w-2xl text-lg mx-auto">
                    Established in Arizona since 2016, CASAS Real Estate entrust
                    agents with the resources to help you get the perfect home
                    at the best price.
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/*ViewBrowseListings section*/}
          <ViewBrowseListings />

          {/* Footer section */}
          <FooterAgent arrayServiceSocial={JSON.parse(localStorage.getItem("serviceSocial"))}/>

          {/* Switcher section */}
          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default browseListingAgent;
