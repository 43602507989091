import React, { Component } from "react";
import Logo from "../assets/images/logo_casas.jpeg";
import swal from "sweetalert";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

class ContactAboutMe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMessage: false,
      messageSent: false,
      phone: "",
    };
    this.saveMessage = this.saveMessage.bind(this);
    this.showMessageSent = this.showMessageSent.bind(this);
  }

  async showMessageSent() {
    const successMessage = await swal({
      title: "Attention!",
      text: "Message sent successfully",
      icon: "success",
      dangerMode: true,
    });

    if (successMessage) {
      window.location.reload();
    }
  }

  //Eduardo:agregue esto
  showErrorMessage() {
    swal({
      title: "Error!",
      text: "Please fill out the email and phone fields.",
      icon: "error",
      dangerMode: true,
    });
  }

  saveMessage(idAgent) {
    this.setState({ loadingMessage: true });
    const form = document.getElementById("formContact");
    //agregue esto
    const email = form.elements["email"].value;
    const phone = this.state.phone;

    // si campos están vacíos
    const formValid = email.trim();
    if (!formValid || !phone) {
      this.showErrorMessage();
      this.setState({ loadingMessage: false });
      this.setState({ formValid: false });
      return;
    }
    // Si estan bien = formValid true
    this.setState({ formValid: true });

    this.setState({ loadingMessage: true });

    form.addEventListener(
      "submit",
      function (e) {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData(form);
        const tenantId = "5ba4c42f-fb2e-448c-8e0f-d65e423b6907";
        const name = formData.get("name");
        const firstname = formData.get("firstname");
        const email = formData.get("email");
        const phone = formData.get("phone");
        const comments = formData.get("comments");

        const urlAPIFind =
          "https://jellyfish-app-nwj2x.ondigitalocean.app/api/tenant/" +
          tenantId +
          "/contact/exitContactAgent/" +
          idAgent +
          "/" +
          email +
          "/" +
          phone;

        const urlAPICreate =
          "https://jellyfish-app-nwj2x.ondigitalocean.app/api/tenant/" +
          tenantId +
          "/contactCreateAgent/" +
          idAgent;

        const urlApiUpdate =
          "https://jellyfish-app-nwj2x.ondigitalocean.app/api/tenant/" +
          tenantId +
          "/contact/exitContact/";

        fetch(urlAPIFind)
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
            const findContact =
              data &&
              data.find(
                (item) => item.email === email && item.phone.includes(phone)
              );

            if (findContact) {
              fetch(urlApiUpdate + findContact.id, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  data: {
                    name: name,
                    firstName: firstname,
                    email: email,
                    phone: phone,
                    comentary: comments,
                  },
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  form.reset();
                })
                .catch((error) => {
                  console.error("Error al actualizar el contacto:", error);
                });
            } else {
              fetch(urlAPICreate, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  data: {
                    name: name,
                    firstName: firstname,
                    email: email,
                    phone: phone,
                    comentary: comments,
                  },
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  form.reset();
                })
                .catch((error) => {
                  console.error("Error al actualizar el contacto:", error);
                });
            }
          })
          .catch((error) => {
            console.error(error);
          });

        this.setState({ loadingMessage: false });
        this.showMessageSent();
      }.bind(this)
    );
  }

  formatPhoneAgent(phone) {
    let newLengthPhone = phone.length - 10;

    return (
      <>
        {"+" +
          phone.slice(0, newLengthPhone) +
          " " +
          phone.slice(newLengthPhone)}
      </>
    );
  }

  render() {
    const { idAgent, address, phone, companyEmail } = this.props;
    const { loadingMessage, formValid } = this.state;

    return (
      <React.Fragment>
        {/* Start */}
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="contact"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6
                className="text-base font-medium uppercase mb-2"
                style={{ color: "#e1dd20" }}
              >
                Let's Connect
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Your Dream Home Awaits
              </h3>

              <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">
                Interested in discovering your dream property? Fill out the
                contact form and we'll be in touch soon.
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-6">
              <div className="lg:col-span-8">
                <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                  <form
                    id="formContact">
                    <p className="mb-0" id="error-msg"></p>
                    <div id="simple-msg"></div>

                    <div className="grid lg:grid-cols-12 lg:gap-6">
                      <div className="lg:col-span-6 mb-5">
                        <input
                          name="name"
                          id="name"
                          type="text"
                          className="form-input"
                          placeholder="Name :"
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <input
                          name="firstname"
                          id="firstname"
                          type="text"
                          className="form-input"
                          placeholder="Firstname :"
                        />
                      </div>

                      <div className="lg:col-span-6 mb-5">
                        <input
                          name="email"
                          id="email"
                          type="email"
                          className="form-input"
                          placeholder="Email :"
                        />
                      </div>
                      <div className="lg:col-span-6 mb-5">
                      <PhoneInput
                          country={"us"} 
                          placeholder="Phone :"           
                          value={this.state.phone}
                          onChange={(phone) => this.setState({ phone })}
                          inputProps={{
                            name: "phone",
                            id: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                          enableSearch={true}
                          inputStyle={{
                            height: "2.5rem",
                            width: "100%",
                            borderRadius: "0.25rem",
                            borderWidth: "1px",
                            borderColor: "inherit",
                            backgroundColor: "transparent",
                            paddingTop: "0.5rem",
                            paddingBottom: "0.5rem",
                            paddingLeft: "2.5rem",
                            paddingRight: "0.75rem",
                            outline: "2px solid transparent",
                            outlineOffset: "2px",
                          }}
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-1">
                      <div className="mb-5">
                        <textarea
                          name="comments"
                          id="comments"
                          className="form-input textarea h-28"
                          placeholder="Message :"
                        ></textarea>
                      </div>
                    </div>
                    <button
                      type={formValid ? "submit" : "button"}
                      id="submit"
                      name="send"
                      onClick={() => this.saveMessage(idAgent)}
                      className="btn hover:bg-strong-gray hover:border-orange-700 text-white rounded-md h-11 justify-center flex items-center"
                      style={{
                        backgroundColor: "#e1dd20",
                        border: "#e1dd20",
                      }}
                    >
                      {loadingMessage && (
                        <i className="uil uil-spinner uil-anim-spin d-block mx-auto animate-[spin_10s_linear_infinite]">
                          {" "}
                        </i>
                      )}
                      Send Message
                    </button>
                  </form>
                </div>
              </div>

              <div className="lg:col-span-4">
                <div className="lg:ms-8">
                  <div className="flex">
                    <div className="icons text-center mx-auto">
                      <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                    </div>

                    <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">
                        Phone
                      </h5>
                      <a
                        href={phone ? "tel:" + phone : "tel:+1 520-329-5850"}
                        className="text-slate-400"
                      >
                        {this.formatPhoneAgent(phone)}
                      </a>
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <div className="icons text-center mx-auto">
                      <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                    </div>

                    <div className="flex-1 ms-6">
                      <h5 className="text-lg dark:text-white mb-2 font-medium">
                        Location
                      </h5>
                      <p className="text-slate-400 mb-2">
                        <a
                          href="https://goo.gl/maps/4soqY73Sr5Xut7Ca7"
                          target="_blank"
                        >
                          3390 N. Campbell Avenue Suite 100 Tucson, AZ 85719
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactAboutMe;
