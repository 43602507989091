import React, { Component } from "react";
import TinySlider from "tiny-slider-react";
import marcelaFuentes from "../assets/images/serviceTeam/marcelaFuentes.png";
import estevanMoreno from "../assets/images/serviceTeam/estevanMoreno.png";
import maricruzFuentes from "../assets/images/serviceTeam/maricruzFuentes.png";
import ceciliaArosemana from "../assets/images/serviceTeam/ceciliaArosemana.png";

import "tiny-slider/dist/tiny-slider.css";

const settings = {
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  controlsText: [
    '<i class="mdi mdi-chevron-left "></i>',
    '<i class="mdi mdi-chevron-right"></i>',
  ],
  gutter: 0,
  responsive: {
    768: {
      items: 2,
    },
  },
};

class Serviceteam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [
        {
          id: "1",
          profile: marcelaFuentes,
          name: "Marcela Fuentes",
          designation: "Designated Broker",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "2",
          profile: estevanMoreno,
          name: "Estevan Moreno",
          designation: "REALTOR® | VP of Property Management",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "3",
          profile: maricruzFuentes,
          name: "Maricruz Fuentes",
          designation: "REALTOR®",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
        {
          id: "4",
          profile: ceciliaArosemana,
          name: "Cecilia Arosemena",
          designation: "REALTOR® | MRP",
          description:
            "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero Launch your campaign and benefit from our expertise.",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="Servicesteams"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6
                className="text-base font-medium uppercase mb-2"
                style={{ color: "#e1dd20", cursor: "pointer" }}
              >
                OUR TEAM
              </h6>

              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Creative Minds
              </h3>
            </div>

            <div className="grid grid-cols-1 mt-8 relative">
              <div className="tiny-two-item">
                <TinySlider settings={settings}>
                  {this.state.review.map((el, index) => (
                    <div className="tiny-slide" key={index}>
                      <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                        <img
                          className="w-24 h-24 lg:w-48 lg:h-auto lg:rounded-none rounded-full mx-auto"
                          src={el.profile}
                          alt=""
                          width="384"
                          height="512"
                        />
                        <div className="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                          <p className="text-base text-slate-500 dark:text-slate-200">
                            {" "}
                            " It seems that only fragments of the original text
                            remain in the Lorem Ipsum texts used today. "{" "}
                          </p>

                          <div>
                            <span
                              className="block mb-1"
                              style={{ color: "#e1dd20" }}
                            >
                              {el.name}
                            </span>
                            <span className="text-slate-400 text-sm dark:text-white/60 block">
                              {el.designation}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Serviceteam;
