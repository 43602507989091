import React, { Component } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { Link } from "react-router-dom";

import Image1 from "../assets/images/portfolio/propiedad1.jpg";
import Image2 from "../assets/images/portfolio/propiedad2.jpg";
import Image3 from "../assets/images/portfolio/propiedad3.jpg";
import Image4 from "../assets/images/portfolio/propiedad4.jpg";
import PropertyDetails from "../pages/property-details";
import airtableHelper from "../modules/fetchAirtableData";

const images = [Image1, Image2, Image3, Image4];

/**
 * Properties section
 */
class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      propertiesList: [],
      isOpenMenu: true,
      photoIndex: 0,
      currentPage: 1,
      propertiesPerPage: 16,
    };
  }

  async componentDidMount() {
    this.airtableShowTable();
  }

  handlePreviousPage = () => {
    const { currentPage } = this.state;
    if (currentPage > 1) {
      this.setState({ currentPage: currentPage - 1 });
    }
  };

  handleNextPage = () => {
    const { currentPage, propertiesPerPage, propertiesList } = this.state;
    const totalPages = Math.ceil(propertiesList.length / propertiesPerPage);
    if (currentPage < totalPages) {
      this.setState({ currentPage: currentPage + 1 });
    }
  };

  airtableShowTable = async () => {
    try {
      const result = await airtableHelper("tbl858ylZRV2ghbm6");
      this.setState({ propertiesList: result });
    } catch (error) {
      console.error("Error general:", error);
    }
  };

  handlePropertyClick = (property) => {
    localStorage.setItem("selectedProperty", JSON.stringify(property));
    window.location.href = "/property-details";
  };

  render() {
    const {
      photoIndex,
      isOpen,
      currentPage,
      propertiesPerPage,
      propertiesList,
    } = this.state;

    const startIndex = (currentPage - 1) * propertiesPerPage;
    const endIndex = startIndex + propertiesPerPage;
    const currentProperties = this.state.propertiesList.slice(
      startIndex,
      endIndex
    );

    console.log({ propertiesList });

    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800 active"
          id="portfolio"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6 className="text-yellow-700 text-base font-medium uppercase mb-2">
                Properties
              </h6>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2 gap-4 mt-4">
              {propertiesList.map((item, index) =>
                item && item.Title ? (
                  <>
                    <div
                      className="relative rounded-md shadow-sm overflow-hidden group"
                      key={item.id ? item.id : index}
                    >
                      {item && item.Images && item.Images.length > 0 ? (
                        <img
                          src={item.Images[0].url}
                          className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                          alt="workimage"
                          style={{
                            width: "100%",
                            height: "200px",
                          }}
                        />
                      ) : (
                        <img
                          src={Image1}
                          className="group-hover:origin-center group-hover:scale-110 group-hover:rotate-3 transition duration-500"
                          alt="workimage"
                          style={{
                            width: "100%",
                            height: "200px",
                          }}
                        />
                      )}
                      <div className="absolute inset-0 group-hover:bg-black opacity-50 transition duration-500 z-0"></div>

                      <div className="content">
                        <div className="icon absolute z-10 opacity-0 group-hover:opacity-100 top-4 end-4 transition-all duration-500"></div>
                        <div className="absolute z-10 opacity-0 group-hover:opacity-100 bottom-4 start-4 transition-all duration-500">
                          <Link
                            /*to={{
                            pathname: `/property-details/`,
                            state: { property: item },
                          }}*/
                            className="h6 text-md font-medium text-white hover:text-yellow-700 transition duration-500"
                            onClick={() => this.handlePropertyClick(item)}
                          >
                            {item.Title}
                          </Link>
                          {/*<p className="text-slate-100 tag mb-0">
                          {item.Description}
                        </p>*/}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )
              )}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Properties;
