import React, { Component } from "react";
import TinySlider from "tiny-slider-react";

import avatar1 from "../assets/images/client/userTestimonial.png";
import avatar2 from "../assets/images/client/userTestimonial.png";
import avatar3 from "../assets/images/client/userTestimonial.png";
import avatar4 from "../assets/images/client/userTestimonial.png";
import CountUp from "react-countup";
import "tiny-slider/dist/tiny-slider.css";

const settings = {
  controls: false,
  mouseDrag: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayButtonOutput: false,
  autoplayTimeout: 3000,
  navPosition: "bottom",
  speed: 400,
  controlsText: [
    '<i class="mdi mdi-chevron-left "></i>',
    '<i class="mdi mdi-chevron-right"></i>',
  ],
  gutter: 0,
  responsive: {
    768: {
      items: 2,
    },
  },
};
class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      review: [
        {
          id: "1",
          profile: avatar1,
          name: "C. Acosta",
          //designation: "Manager",
          description:
            "She was very friendy, professional and knwoledgeable of the market, best areas to live in and in the city of Tucson. We couldn't be more pleased and higlighy recommend her and would use her again.",
        },
        {
          id: "2",
          profile: avatar2,
          name: "A. kim",
          //designation: "Manager",
          description:
            "We connected with CASAS and we couldn't have had a better buying agent. They took great care of us and we would recommend them to anyone buying or selling.",
        },
        {
          id: "3",
          profile: avatar3,
          name: "Jane Doe",
          //designation: "Manager",
          description:
            "CASAS was great during the entire process. They helped me purchase my very first home. Answered all my questions (even if I asked them more than once), and were honest. I highly recommend them!	",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
        {/* Review Start  */}
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="testi"
        >
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6
                className="text-base font-medium uppercase mb-2"
                style={{ color: "#e1dd20" }}
              >
                TESTIMONIAL
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Client's Review
              </h3>

              {/*<p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>*/}
            </div>

            <div className="grid grid-cols-1 mt-8 relative">
              <div className="tiny-two-item">
                <TinySlider settings={settings}>
                  {this.state.review.map((el, index) => (
                    <div className="tiny-slide" key={index}>
                      <div className="lg:flex p-6 lg:p-0 relative rounded-md shadow shadow-slate-200 dark:shadow-slate-700 bg-white dark:bg-slate-900 overflow-hidden m-2">
                        <div className="pt-6 lg:p-6 text-center lg:text-left space-y-4">
                          <p className="text-base text-slate-500 dark:text-slate-200 text-center">
                            {" "}
                            {el.description}
                          </p>

                          <div>
                            <span
                              className="block mb-1 text-center"
                              style={{ color: "#e1dd20" }}
                            >
                              {el.name}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </TinySlider>
              </div>
            </div>
          </div>

          <div className="container mt-12">
            <div className="grid grid-cols-2 md:grid-cols-4">
              <div className="counter-box position-relative text-center">
                <h3 className="font-medium text-3xl mb-2 dark:text-white">
                  <CountUp
                    start={3}
                    className="counter-value"
                    end={40}
                    duration={2.75}
                  />
                  +
                </h3>
                <span className="counter-head text-slate-400 dark:text-slate-300">
                  Projects
                </span>
              </div>

              <div className="counter-box position-relative text-center">
                <h3 className="font-medium text-3xl mb-2 dark:text-white">
                  <CountUp
                    start={1}
                    className="counter-value"
                    end={200}
                    duration={2.75}
                  />
                  +
                </h3>
                <span className="counter-head text-slate-400 dark:text-slate-300">
                  Clients
                </span>
              </div>

              <div className="counter-box position-relative text-center">
                <h3 className="font-medium text-3xl mb-2 dark:text-white">
                  <CountUp
                    start={200}
                    className="counter-value"
                    end={457}
                    duration={2.75}
                  />
                  K
                </h3>
                <span className="counter-head text-slate-400 dark:text-slate-300">
                  Members
                </span>
              </div>

              <div className="counter-box position-relative text-center">
                <h3 className="font-medium text-3xl mb-2 dark:text-white">
                  <CountUp
                    start={100}
                    className="counter-value"
                    end={150}
                    duration={2.75}
                  />
                  +
                </h3>
                <span className="counter-head text-slate-400 dark:text-slate-300">
                  Employee
                </span>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Review;
