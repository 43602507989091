import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo_casas.jpeg";
import LogoDark from "../assets/images/logo_casas.jpeg";

import Lightbox from "react-18-image-lightbox";

import "../../node_modules/react-modal-video/scss/modal-video.scss";

import Switcher from "../component/Switcher";

import BackgroudImage from "../assets/images/casasre_bg2.jpeg";

import Image1 from "../assets/images/portfolio/propiedad1.jpg";

/**
 * Index four
 */
class IndexFour extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
      videoModal: false,
      selectedProperty: null,
      selectedImage: null,
      currentIndex: 0,
    };
    this.openModal = this.openModal.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.windowScroll);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Video modal open
   */
  openModal() {
    this.setState({ videoModal: true });
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }
  toggleMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  nextPage() {
    window.location.href = "/index";
  }

  pagePropertyManagement() {
    window.location.href = "/property-management";
  }

  componentDidMount() {
    const selectedProperty = JSON.parse(
      localStorage.getItem("selectedProperty")
    );
    this.setState({ selectedProperty });
  }

  componentWillUnmount() {
    localStorage.removeItem("selectedProperty");
  }

  pageInicio() {
    window.location.href = "/";
  }

  selectImage(img) {
    this.setState({
      selectedImage: img,
    });
  }

  handlePrevClick = () => {
    const { currentIndex } = this.state;
    this.setState((prevState) => ({
      currentIndex:
        (prevState.currentIndex -
          1 +
          prevState.selectedProperty.Images.length) %
        prevState.selectedProperty.Images.length,
    }));
  };

  handleNextClick = () => {
    const { currentIndex } = this.state;
    this.setState((prevState) => ({
      currentIndex:
        (prevState.currentIndex + 1) % prevState.selectedProperty.Images.length,
    }));
  };

  render() {
    const { isOpen, currentIndex, selectedProperty, selectedImage } =
      this.state;
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";

    const visibleImages =
      selectedProperty && selectedProperty.Images
        ? selectedProperty.Images.slice(currentIndex, currentIndex + 4)
        : [];

    //console.log({ selectedProperty });
    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a className="navbar-brand" href="#">
                <span>
                  <img
                    src={LogoDark}
                    className="inline-block dark:hidden"
                    alt=""
                    width="50"
                    height="50"
                  />
                  <img
                    src={LogoLight}
                    className="hidden dark:inline-block"
                    alt=""
                    width="50"
                    height="50"
                  />
                </span>
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.facebook.com/CasasTucson/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                    </Link2>
                  </li>
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.instagram.com/casas.re/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                    </Link2>
                  </li>
                </ul>
                <button
                  data-collapse="menu-collapse"
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`${toggleClass} navigation lg_992:order-1 lg_992:flex`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={this.nextPage}
                  >
                    <span className="nav-link">Home</span>
                  </Link>
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">About us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">Our Team</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">Browse Listing</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">Testimonial</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">Blog</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageInicio}
                    duration={500}
                  >
                    <span className="nav-link">Contact us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeClass="active"
                    to={"details"}
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Property Management</span>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section
            style={{ backgroundImage: `url(${BackgroudImage})` }}
            className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
            id="details"
          >
            <div className="absolute inset-0 bg-black opacity-80"></div>
            <div className="container relative">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-6">
                <div class="flex flex-col md:flex-row -mx-4">
                  <div class="md:flex-1 px-4">
                    <div class="h-64 md:h-80 rounded-lg bg-gray-100 mb-4">
                      <div class="h-64 md:h-80 rounded-lg bg-gray-100 mb-4 flex items-center justify-center">
                        {currentIndex ? (
                          <img
                            src={selectedProperty.Images[currentIndex].url}
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                          />
                        ) : (
                          <img
                            src={
                              selectedProperty && selectedProperty.Images
                                ? selectedProperty.Images[0].url
                                : Image1
                            }
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            onClick={() => this.setState({ isOpen: true })}
                          />
                        )}
                      </div>
                    </div>

                    {selectedProperty && selectedProperty.Images && (
                      <div className="mt-4 flex items-center justify-center">
                        <button
                          className="bg-gray-200 p-2 rounded-full shadow-lg"
                          onClick={this.handlePrevClick}
                        >
                          &lt;
                        </button>
                        <button
                          className="bg-gray-200 p-2 rounded-full shadow-lg ml-4"
                          onClick={this.handleNextClick}
                        >
                          &gt;
                        </button>
                      </div>
                    )}
                  </div>

                  <div class="md:flex-1 px-4">
                    <h2 class="mb-2 leading-tight tracking-tight font-bold text-yellow-700 text-2xl md:text-3xl">
                      {selectedProperty && selectedProperty.Title
                        ? selectedProperty.Title
                        : ""}
                    </h2>

                    {selectedProperty && selectedProperty.Price && (
                      <div class="flex items-center space-x-4 my-4">
                        <div>
                          <div class="rounded-lg bg-gray-100 flex py-2 px-3">
                            <span class="text-yellow-700 mr-1 mt-1">$</span>
                            <span class="font-bold text-yellow-700 text-3xl">
                              {selectedProperty.Price}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}

                    {selectedProperty && selectedProperty.Description && (
                      <div class="md:flex-1 px-4">
                        <h2 class="mb-2 leading-tight tracking-tight font-bold text-gray-400 text-2xl md:text-3xl">
                          {selectedProperty.Description}
                        </h2>
                      </div>
                    )}

                    <Link
                      className="bg-yellow-600 hover:bg-yellow-700 text-white font-semibold py-2 px-4 rounded cursor-pointer"
                      onClick={this.pagePropertyManagement}
                    >
                      Back to Property List
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {isOpen && (
              <>
                <Lightbox
                  mainSrc={Image1}
                  onCloseRequest={() => this.setState({ isOpen: false })}
                />
              </>
            )}
          </section>

          {/* Switcher section */}
          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default IndexFour;
