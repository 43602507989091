import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo_casas.jpeg";
import LogoDark from "../assets/images/logo_casas.jpeg";

import "../../node_modules/react-modal-video/scss/modal-video.scss";

import BackgroudImage from "../assets/images/casasre_bg2.jpeg";

import AboutMeAgent from "../component/AboutMeAgent";
import Blog from "../component/Blog";
import ContactAboutAgent from "../component/ContactAboutAgent";
import FooterAgent from "../component/FooterAgent";
import Switcher from "../component/Switcher";
import BrowseListings from "../component/BrowseListings";
import Testimonial from "../component/Testimonial";
import About from "../component/About";

class IndexTwo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
      videoModal: false,
      idAgent: "",
      nameAgent: "",
      textAboutMe: "",
      phoneAgent: "",
      companyEmail: "",
      jobPosition: "",
      serviceSocial: [],
      avatar: {},
      logoCompany: {},
    };
    this.openModal = this.openModal.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.windowScroll);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Video modal open
   */
  openModal() {
    this.setState({ videoModal: true });
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }
  toggleMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getInformationAgent();
  }

  navegationPage() {
    window.location.href = "/";
  }

  getInformationAgent = () => {
    fetch(
      "https://jellyfish-app-nwj2x.ondigitalocean.app/api/tenant/website/agent/" +
        window.SUBDOMAIN[1],
      {}
    )
      .then((response) => response.json())
      .then((data) => {
        console.log({ data });
        this.setState({
          idAgent: data.agentRelationId,
          nameAgent: data.getAgent.fullName.toUpperCase(),
          textAboutMe: data.aboutMe,
          phoneAgent: data.phone,
          companyEmail: data.companyEmail,
          jobPosition: data.jobPosition,
          serviceSocial: data.socialMedia ? data.socialMedia : [],
          avatar: data && data.avatars ? data.avatars[0] : null,
          logoCompany: data && data.logoUploads ? data.logoUploads[0] : null,
        });
        localStorage.setItem("serviceSocial", JSON.stringify(data.socialMedia));
      })
      .catch((error) => {});
  };

  openPageBrowseListing() {
    window.location.href = window.SUBDOMAIN[1] + "/browse-listing-agent";
  }

  render() {
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";

    const {
      idAgent,
      nameAgent,
      textAboutMe,
      phoneAgent,
      companyEmail,
      jobPosition,
      serviceSocial,
      avatar,
      logoCompany,
    } = this.state;
    console.log(this.state);
    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a className="navbar-brand" href="#">
                <span className="inline-block dark:hidden">
                  <img
                    src={LogoDark}
                    className="l-dark"
                    alt=""
                    width="110"
                    height="110"
                  />
                  <img
                    src={LogoLight}
                    className="l-light"
                    alt=""
                    width="110"
                    height="110"
                  />
                </span>
                <img
                  src={LogoLight}
                  className="hidden dark:inline-block"
                  alt=""
                  width="110"
                  height="110"
                />
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  {serviceSocial &&
                    serviceSocial.map((social) => (
                      <>
                        {social.serviceSocialWebSite === "facebook" && (
                          <>
                            <li className="inline ms-1">
                              <Link2 to={social.urlWebSite} target="_blank">
                                <span className="login-btn-primary">
                                  <span
                                    style={{
                                      backgroundColor: "#5d5d5d",
                                      borderColor: "#5d5d5d",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                                  >
                                    <i
                                      className="uil uil-facebook-f align-middle"
                                      title="facebook"
                                    ></i>
                                  </span>
                                </span>
                                <span className="login-btn-light">
                                  <span
                                    style={{
                                      backgroundColor: "#f2f1f1",
                                      borderColor: "#f2f1f1",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                                  >
                                    <i
                                      className="uil uil-facebook-f align-middle"
                                      title="facebook"
                                    ></i>
                                  </span>
                                </span>
                              </Link2>
                            </li>
                          </>
                        )}

                        {social.serviceSocialWebSite === "twitter" && (
                          <>
                            <li className="inline ms-1">
                              <Link2 to={social.urlWebSite} target="_blank">
                                <span className="login-btn-primary">
                                  <span
                                    style={{
                                      backgroundColor: "#5d5d5d",
                                      borderColor: "#5d5d5d",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                                  >
                                    <i
                                      className="uil uil-twitter align-middle"
                                      title="twitter"
                                    ></i>
                                  </span>
                                </span>
                                <span className="login-btn-light">
                                  <span
                                    style={{
                                      backgroundColor: "#f2f1f1",
                                      borderColor: "#f2f1f1",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                                  >
                                    <i
                                      className="uil uil-twitter align-middle"
                                      title="twitter"
                                    ></i>
                                  </span>
                                </span>
                              </Link2>
                            </li>
                          </>
                        )}

                        {social.serviceSocialWebSite === "linkedIn" && (
                          <>
                            <li className="inline ms-1">
                              <Link2 to={social.urlWebSite} target="_blank">
                                <span className="login-btn-primary">
                                  <span
                                    style={{
                                      backgroundColor: "#5d5d5d",
                                      borderColor: "#5d5d5d",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                                  >
                                    <i
                                      className="uil uil-linkedin align-middle"
                                      title="linkedin"
                                    ></i>
                                  </span>
                                </span>
                                <span className="login-btn-light">
                                  <span
                                    style={{
                                      backgroundColor: "#f2f1f1",
                                      borderColor: "#f2f1f1",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                                  >
                                    <i
                                      className="uil uil-linkedin align-middle"
                                      title="linkedin"
                                    ></i>
                                  </span>
                                </span>
                              </Link2>
                            </li>
                          </>
                        )}

                        {social.serviceSocialWebSite === "instagram" && (
                          <>
                            <li className="inline ms-1">
                              <Link2 to={social.urlWebSite} target="_blank">
                                <span className="login-btn-primary">
                                  <span
                                    style={{
                                      backgroundColor: "#5d5d5d",
                                      borderColor: "#5d5d5d",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                                  >
                                    <i
                                      className="uil uil-instagram align-middle"
                                      title="instagram"
                                    ></i>
                                  </span>
                                </span>
                                <span className="login-btn-light">
                                  <span
                                    style={{
                                      backgroundColor: "#f2f1f1",
                                      borderColor: "#f2f1f1",
                                    }}
                                    className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                                  >
                                    <i
                                      className="uil uil-instagram align-middle"
                                      title="instagram"
                                    ></i>
                                  </span>
                                </span>
                              </Link2>
                            </li>
                          </>
                        )}
                      </>
                    ))}
                </ul>

                {logoCompany && (
                  <a className="navbar-brand" style={{marginLeft: '5px'}}>
                    <span className="inline-block dark:hidden">
                      <img
                        src={logoCompany.downloadUrl}
                        className="l-dark"
                        alt=""
                        width="110"
                        height="110"
                      />
                      <img
                        src={logoCompany.downloadUrl}
                        className="l-light"
                        alt=""
                        width="110"
                        height="110"
                      />
                    </span>
                    <img
                      src={logoCompany.downloadUrl}
                      className="hidden dark:inline-block"
                      alt=""
                      width="110"
                      height="110"
                    />
                  </a>
                )}

                <button
                  data-collapse="menu-collapse"
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`${toggleClass} navigation lg_992:order-1 lg_992:flex`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={this.openPageBrowseListing}
                  >
                    <span className="nav-link">Browse Listing</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="testi"
                  >
                    <span className="nav-link">Testimonials</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="blog"
                  >
                    <span className="nav-link">Blog</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="about"
                  >
                    <span className="nav-link">About Us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="aboutMe"
                  >
                    <span className="nav-link">About Me</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="contact"
                  >
                    <span className="nav-link">Contact Me</span>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section
            style={{ backgroundImage: `url(${BackgroudImage})` }}
            className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
            data-jarallax
            data-speed="0.5"
            id="home"
          >
            <div className="absolute inset-0 bg-black opacity-70"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 text-center">
                <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium position-relative">
                  {nameAgent ? nameAgent : ""}
                </h4>

                <p className="text-white opacity-50 mb-0 max-w-2xl text-lg-agent-jobposition mx-auto">
                  {jobPosition ? jobPosition : ""}
                </p>
              </div>
            </div>
          </section>

          {/* About section */}
          <AboutMeAgent avatar={avatar} aboutMe={textAboutMe} />

          {/* Browse Listing section */}
          <BrowseListings logoCompany={logoCompany?.downloadUrl} />

          {/*Aboout section */}
          <About />

          {/*Testimonials section*/}
          <Testimonial />

          {/* Blog section */}
          {<Blog />}

          {/* Contact section */}
          <ContactAboutAgent
            idAgent={idAgent}
            phone={phoneAgent}
            companyEmail={companyEmail}
          />

          {/* Footer section */}
          <FooterAgent arrayServiceSocial={serviceSocial} />
          {/* Switcher section */}
          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default IndexTwo;
