import React, { Component } from "react";
import { Link as Link2 } from "react-router-dom";

import imagen from "../assets/images/client/userTestimonial.png";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

class AboutMeAgent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoModal: false,
      aboutMe: "",
    };

    this.openModal = this.openModal.bind(this);
  }

  /**
   * Video modal open
   */
  openModal() {
    this.setState({ videoModal: true });
  }

  render() {
    const { avatar, aboutMe } = this.props;
    return (
      <React.Fragment>
        <section
          className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800"
          id="aboutMe"
        >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
              <div className="lg:col-span-5">
                <div className="relative">
                  <img
                    src={avatar ? avatar.downloadUrl : imagen}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                  />
                </div>
              </div>
              {/* end col */}

              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  <h6
                    className="text-base font-medium uppercase mb-2"
                    style={{ color: "#e1dd20" }}
                  >
                    About Me
                  </h6>

                  {aboutMe ? (
                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                      {aboutMe}
                    </p>
                  ) : (
                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                      {""}
                    </p>
                  )}

                  <div className="relative mt-10">
                    <a
                      href="#contact"
                      className="btn hover:bg-strong-gray hover:border-orange-700 text-white rounded-md"
                      style={{ backgroundColor: "#e1dd20", border: "#e1dd20" }}
                    >
                      Contact Me
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutMeAgent;
