import React, { Component } from "react";
import "./assets/css/tailwind.css";
import "./assets/scss/tailwind.scss";

import "./assets/scss/icons.scss";

import { Route, Routes } from "react-router-dom";

import Index from "./pages/property-management.js";
import PageAgents from "./pages/page-agents.js";
import IndexThree from "./pages/index-three.js";
import IndexFour from "./pages/property-details";
import OurtTeam from "./pages/OurtTeam.js";
import BrowseListing from "./pages/BrowseListing.js";
import IndexSeven from "./pages/index-seven";
import BrowseListingAgent from "./pages/BrowseListingAgent";

import Loader from "./component/Loader";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      namePage: "",
    };
    this.handleRouteChange = this.handleRouteChange.bind(this);
  }

  componentDidMount() {
    const objectNamePage = {
      "property-management": "property-management",
      "property-details": "property-details",
      "browse-listing": "browse-listing",
      "Ourt-team": "Ourt-team",
    };
    console.log(window.SUBDOMAIN);
    if (window.SUBDOMAIN && window.SUBDOMAIN[1]) {
      if (!objectNamePage[window.SUBDOMAIN[1]]) {
        this.setState({
          namePage: window.SUBDOMAIN[1],
        });
      }
    }
    document.documentElement.setAttribute("dir", "ltr");
    this.handleRouteChange();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleRouteChange();
    }
  }

  // Route change method
  handleRouteChange() {
    this.setState({ loading: true });
  }

  render() {
    const { namePage } = this.state;
    return (
      <React.Fragment>
        {this.state.loading && <Loader />}
        <Routes>
          {namePage === "" ? (
            <>
              <Route path="/" element={<IndexThree />} />
              <Route path="/property-management" element={<Index />} />
              <Route path="/property-details" element={<IndexFour />} />
              <Route path="/Ourt-team" element={<OurtTeam />} />
              <Route path="/index-seven" element={<IndexSeven />} />
              <Route path="/browse-listing" element={<BrowseListing />}></Route>
            </>
          ) : (
            <>
              {/*REVISAR EL NAME PAGE COMO IMPLEMENTAR OTRA LOGINA */}
              <Route path={namePage} element={<PageAgents />} />
              <Route
                path={namePage + "/browse-listing-agent"}
                element={<BrowseListingAgent />}
              />
            </>
          )}
        </Routes>
      </React.Fragment>
    );
  }
}

export default App;
