import React, { Component, useState } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import LogoLight from "../assets/images/logo_casas.jpeg";
import LogoDark from "../assets/images/logo_casas.jpeg";

import BackgroudImage from "../assets/images/casasre_bg2.jpeg";

import About from "../component/About";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Testimonial from "../component/Testimonial";
import Switcher from "../component/Switcher";
import Agent from "../component/Agent";
import Blog from "../component/Blog";
import Portfolio from "../component/Portfolio";
import Team from "../component/Team";
import BrowseListings from "../component/BrowseListings";
import Autocomplete from "react-google-autocomplete";
import Ourteam from "../component/Ourteam";

/**
 * Index Three
 */

class IndexThree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
      videoModal: false,
      address: "",
      rowsWebSite: [],
      countWebsite: 0,
    };
    this.openModal = this.openModal.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.saveProcessAddress = this.saveProcessAddress.bind(this);
    this.closeMenu = this.closeMenu(this);

    window.addEventListener("scroll", this.windowScroll);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Video modal open
   */
  openModal() {
    this.setState({ videoModal: true });
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  toggleMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  closeMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    this.getInformationWebSite();

    let idView = localStorage.getItem("idView")
      ? localStorage.getItem("idView")
      : null;

    if (idView) {
      let elemento = document.querySelector("#" + idView);

      elemento.scrollIntoView();

      localStorage.removeItem("idView");
    }
  }

  saveProcessAddress() {
    const form = document.getElementById("formAddress");
    form.addEventListener(
      "submit",
      function (event) {
        event.preventDefault();
        var input = document.getElementById("googleAutocomplete");
        if (input.value) {
          this.setState({ address: input.value });
          window.location.href = "#contact";
        }
      }.bind(this)
    );
  }

  getInformationWebSite = () => {
    const tenantId = "5ba4c42f-fb2e-448c-8e0f-d65e423b6907";

    fetch(
      "https://jellyfish-app-nwj2x.ondigitalocean.app/api/tenant/website/websiteFindCompany/" +
        tenantId,
      {}
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          countWebsite: data.count,
          rowsWebSite: data.rows,
        });
      })
      .catch((error) => {});
  };

  pageOurTeams() {
    window.location.href = "Ourt-team";
  }

  pagePropertyManagement() {
    window.location.href = "property-management";
  }

  openPageBrowseListing() {
    window.location.href = "browse-listing";
  }

  render() {
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";

    const { address, rowsWebSite, countWebsite } = this.state;

    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a className="navbar-brand" href="#">
                <span className="inline-block dark:hidden">
                  <img
                    src={LogoDark}
                    className="l-dark"
                    alt=""
                    width="110"
                    height="110"
                  />
                  <img
                    src={LogoLight}
                    className="l-light"
                    alt=""
                    width="110"
                    height="110"
                  />
                </span>
                <img
                  src={LogoLight}
                  className="hidden dark:inline-block"
                  alt=""
                  width="110"
                  height="110"
                />
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.facebook.com/CasasTucson/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                    </Link2>
                  </li>

                  <li className="inline ms-1">
                    <Link2
                      to="https://www.instagram.com/casas.re/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                    </Link2>
                  </li>
                </ul>
                <button
                  data-collapse="menu-collapse"
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`navigation lg_992:order-1 lg_992:flex ${toggleClass}`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    to="about"
                  >
                    <span className="nav-link" id="navbar-navlist">
                      About Us
                    </span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    //to="ourTeam"
                    onClick={this.pageOurTeams}
                  >
                    <span className="nav-link">Our Team</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={this.openPageBrowseListing}
                  >
                    <span className="nav-link">Browse Listing</span>
                  </Link>

                  <Link
                    className="nav-item"
                    to="testi"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Testimonial</span>
                  </Link>

                  <Link
                    className="nav-item"
                    to="blog"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Blog</span>
                  </Link>

                  <Link
                    className="nav-item"
                    to="contact"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Contact Us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={this.pagePropertyManagement}
                  >
                    <span className="nav-link">Property Management</span>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section
            style={{ backgroundImage: `url(${BackgroudImage})` }}
            className="py-36 md:py-72 w-full table relative bg-center bg-cover jarallax"
            data-jarallax
            data-speed="0.5"
            id="home"
          >
            <div className="absolute inset-0 bg-black opacity-70"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 text-center">
                <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                  Your Real Estate Journey, Made Seamless.
                </h4>

                <p className="text-white opacity-50 mb-0 max-w-2xl text-lg mx-auto">
                  Explore your perfect property in Tucson, Arizona using the
                  search tool below.
                </p>

                <div className="text-center subcribe-form mt-4 pt-2">
                  <form className="relative mx-auto" id="formAddress">
                    <div>
                      <Autocomplete
                        apiKey="AIzaSyCcP7etC79s_FxNvgVknuHlKqWE-6VbHmM"
                        options={{
                          componentRestrictions: { country: ["US", "MX"] },
                          types: ["address"],
                        }}
                        className="rounded-full bg-white opacity-70 border"
                        id="googleAutocomplete"
                      />

                      <button
                        className="btn hover:bg-strong-gray hover:border-orange-700 text-white rounded-full"
                        style={{
                          backgroundColor: "#e1dd20",
                          border: "#e1dd20",
                        }}
                        onClick={this.saveProcessAddress}
                        type="submit"
                      >
                        Submit <i className="uil uil-arrow-right"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>

          <About />

          <Ourteam rows={rowsWebSite} count={countWebsite} />

          <BrowseListings />

          <Testimonial />

          <Blog />

          <Contact address={address} />

          <Footer />

          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default IndexThree;
