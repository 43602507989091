import React, { Component } from "react";
import { Link } from "react-scroll";
import { Link as Link2 } from "react-router-dom";

import Typed from "typed.js";

import LogoLight from "../assets/images/logo_casas.jpeg";
import LogoDark from "../assets/images/logo_casas.jpeg";

import BackgroudImage from "../assets/images/casasre_bg2.jpeg";

import Services from "../component/Services";
import Pricing from "../component/Pricing";
import Contact from "../component/Contact";
import Footer from "../component/Footer";
import Switcher from "../component/Switcher";
import ServiceTeam from "../component/ServiceTeam";
import Properties from "../component/Properties";

/**
 * Index Component
 */
class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenMenu: true,
      arrow: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);

    this.handleScroll = this.handleScroll.bind(this);

    window.addEventListener("scroll", this.windowScroll);

    setTimeout(() => {
      new Typed("#typed", {
        strings: [
          "<b>Business</b>",
          "<b>Startups</b>",
          "<b>Digital Agency</b>",
          "<b>Marketing</b>",
        ],
        backDelay: 2000,
        loop: true,
        startDelay: 300,
        typeSpeed: 100,
        backSpeed: 100,
      });
    }, 500);
  }

  handleScroll() {
    if (
      document.body.scrollTop >= 500 ||
      document.documentElement.scrollTop >= 500
    ) {
      this.setState({
        arrow: true,
      });
    } else {
      this.setState({
        arrow: false,
      });
    }
  }

  /**
   * Window scroll
   */
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop >= 50 ||
      document.documentElement.scrollTop >= 50
    ) {
      navbar.classList.add("is-sticky");
    } else {
      navbar.classList.remove("is-sticky");
    }
  }

  toggleMenu() {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  pageInicio() {
    window.location.href = "/";
  }

  navegationPage() {
    window.location.href = "/";
  }

  navegationMenuSection(nameSeccion) {
    window.location.href = "/";
    localStorage.setItem("idView", nameSeccion);
  }

  pageOurTeams() {
    window.location.href = "Ourt-team";
  }

  openPageBrowseListing() {
    window.location.href = "/browse-listing";
  }

  render() {
    const toggleClass = this.state.isOpenMenu ? "hidden" : "";

    return (
      <React.Fragment>
        <div>
          <nav className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
              <a
                className="navbar-brand"
                onClick={this.navegationPage}
                style={{ cursor: "pointer" }}
              >
                <span className="inline-block dark:hidden">
                  <img
                    src={LogoDark}
                    className="l-dark"
                    alt=""
                    width="110"
                    height="110"
                  />
                  <img
                    src={LogoLight}
                    className="l-light"
                    alt=""
                    width="110"
                    height="110"
                  />
                </span>
                <img
                  src={LogoLight}
                  className="hidden dark:inline-block"
                  alt=""
                  width="110"
                  height="110"
                />
              </a>

              <div className="nav-icons flex items-center lg_992:order-2 ms-auto">
                <ul className="list-none menu-social mb-0">
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.facebook.com/CasasTucson/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i
                            className="uil uil-facebook-f align-middle"
                            title="facebook"
                          ></i>
                        </span>
                      </span>
                    </Link2>
                  </li>
                  <li className="inline ms-1">
                    <Link2
                      to="https://www.instagram.com/casas.re/"
                      target="_blank"
                    >
                      <span className="login-btn-primary">
                        <span
                          style={{
                            backgroundColor: "#5d5d5d",
                            borderColor: "#5d5d5d",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-yellow-700 hover:border-orange-700 text-white"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                      <span className="login-btn-light">
                        <span
                          style={{
                            backgroundColor: "#f2f1f1",
                            borderColor: "#f2f1f1",
                          }}
                          className="btn btn-sm btn-icon rounded-full hover:bg-gray-200 dark:bg-slate-900 dark:hover:bg-gray-700 hover:border-gray-100 dark:border-gray-700 dark:hover:border-gray-700"
                        >
                          <i className="uil uil-instagram"></i>
                        </span>
                      </span>
                    </Link2>
                  </li>
                </ul>
                <button
                  type="button"
                  className="collapse-btn inline-flex items-center ms-3 text-dark dark:text-white lg_992:hidden"
                  onClick={this.toggleMenu}
                >
                  <span className="sr-only">Navigation Menu</span>
                  <i className="mdi mdi-menu mdi-24px"></i>
                </button>
              </div>

              <div
                className={`${toggleClass} navigation lg_992:order-1 lg_992:flex`}
                id="menu-collapse"
              >
                <ul className="navbar-nav nav-light" id="navbar-navlist">
                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={() => this.navegationMenuSection("about")}
                    duration={500}
                  >
                    <span className="nav-link">About us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.pageOurTeams}
                    duration={500}
                  >
                    <span className="nav-link">Our Team</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={this.openPageBrowseListing}
                    duration={500}
                  >
                    <span className="nav-link">Browse Listing</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={() => this.navegationMenuSection("testi")}
                    duration={500}
                  >
                    <span className="nav-link">Testimonial</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={() => this.navegationMenuSection("blog")}
                    duration={500}
                  >
                    <span className="nav-link">Blog</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeclassname="active"
                    spy={true}
                    smooth={true}
                    onClick={() => this.navegationMenuSection("contact")}
                    duration={500}
                  >
                    <span className="nav-link">Contact us</span>
                  </Link>

                  <Link
                    className="nav-item"
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    duration={500}
                  >
                    <span className="nav-link">Property Management</span>
                  </Link>
                </ul>
              </div>
            </div>
          </nav>
          <section
            style={{ backgroundImage: `url(${BackgroudImage})` }}
            className="py-36 lg:py-64 w-full table relative bg-center bg-cover"
            data-jarallax
            data-speed="0.5"
            id="property"
          >
            <div className="absolute inset-0 bg-black opacity-70"></div>
            <div className="container relative">
              <div className="grid grid-cols-1 mt-12">
                <h4 className="text-white lg:text-5xl text-4xl lg:leading-normal leading-normal font-medium mb-7 position-relative">
                  Providing Brilliant Ideas <br />
                  For Your{" "}
                  <span
                    className="typewrite relative text-type-element"
                    id="typed"
                    data-period="2000"
                    data-type='[ "Business", "Startups", "Digital Agency", "Marketing" ]'
                  ></span>
                </h4>
              </div>
            </div>
          </section>

          {/* Properties section */}
          <Properties />

          {/* Pricing section */}
          <Pricing />

          {/* Service section */}
          <Services />

          {/* Services Team section */}
          <ServiceTeam />

          {/*services team srcicio*/}
          <Contact />

          {/* Footer section */}
          <Footer />

          {/* Switcher section */}
          <Switcher />
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
