import React, { Component } from "react";

import Blog1 from "../assets/images/blog/blog1.webp";
import Blog2 from "../assets/images/blog/blog2.jpg";
import Blog3 from "../assets/images/blog/blog3.webp";

import { Link as Link2 } from "react-router-dom";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blog: [
        {
          id: "1",
          title:
            "Mild recession, elevated mortgage rates eyed in 2024: Fannie Mae",
          description:
            "Fannie Mae economists predict 'mild recession' in H1 2024",
          image: Blog1,
          link: "https://www.inman.com/2023/09/18/mild-recession-elevated-mortgage-rates-eyed-in-2024-fannie-mae/",
        },
        {
          id: "2",
          title:
            "Existing-home sales fall off 0.7% led by Southern, Western markets",
          description:
            "Sales of existing homses slid 0.7 percent between July and August to a seasonally adjusted annual rate of 4.04 million but held steady in the Northeast and rose in the Midwest.",
          image: Blog2,
          link: "https://www.inman.com/2023/09/21/existing-home-sales-fall-off-0-7-led-by-southern-western-markets/",
        },
        {
          id: "3",
          title:
            "Rent remains near record highs, but the balance of power is shifting",
          description:
            "At $2,052, the median monthly rent remains near its all-time high.",
          image: Blog3,
          link: "https://www.inman.com/2023/09/12/rent-remains-near-record-highs-but-the-balance-of-power-is-shifting/",
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="relative md:py-24 py-16" id="blog">
          <div className="container">
            <div className="grid grid-cols-1 pb-8 text-center">
              <h6
                className="text-base font-medium uppercase mb-2"
                style={{ color: "#e1dd20" }}
              >
                Blogs
              </h6>
              <h3 className="mb-4 md:text-2xl text-xl font-medium dark:text-white">
                Latest News
              </h3>

              {/*<p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Launch your campaign and benefit from our expertise on designing and managing conversion centered Tailwind CSS html page.</p>*/}
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6 mt-8">
              {this.state.blog.map((item, key) => (
                <div
                  key={key}
                  className="blog relative rounded-md shadow shadow-slate-200 dark:shadow-slate-800 overflow-hidden"
                >
                  <img src={item.image} alt="" />
                  <div className="content p-6">
                    <Link2
                      to="#"
                      className="text-lg hover:text-yellow-700 dark:text-white dark:hover:text-yellow-700 transition-all duration-500 ease-in-out font-medium"
                    >
                      {item.title}
                    </Link2>
                    <p className="text-slate-400 mt-3">{item.description}</p>

                    <div className="mt-5">
                      <Link2
                        to={item.link}
                        className="btn btn-link hover:text-yellow-700 dark:hover:text-yellow-700 after:bg-yellow-600 dark:text-white transition duration-500"
                        target="_blank"
                      >
                        Read More <i className="uil uil-arrow-right"></i>
                      </Link2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
