import React, { Component } from "react";
import AboutImage from "../assets/images/casasre_bg.jpeg";
import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/scss/modal-video.scss";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoModal: false,
      aboutMe: "",
    };

    this.openModal = this.openModal.bind(this);
  }

  /**
   * Video modal open
   */
  openModal() {
    this.setState({ videoModal: true });
  }

  render() {
    const { aboutMe } = this.state;

    return (
      <React.Fragment>
          <section
            className="relative py-16 md:py-42 bg-gray-50 dark:bg-slate-800"
            id="about"
          >
          <div className="container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
              <div className="lg:col-span-5">
                <div className="relative">
                  <img
                    src={AboutImage}
                    className="rounded-lg shadow-lg relative"
                    alt=""
                  />
                </div>
              </div>
              {/* end col */}

              <div className="lg:col-span-7">
                <div className="lg:ms-7">
                  <h6
                    className="text-base font-medium uppercase mb-2"
                    style={{ color: "#e1dd20" }}
                  >
                    About Us
                  </h6>

                  {aboutMe ? (
                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                      {aboutMe}
                    </p>
                  ) : (
                    <p className="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">
                      CASAS Real Estate was established in 2016 as a boutique
                      style brokerage with a focus on first class customer
                      service and professionalism. Our Agents provide
                      exceptional representation to each and every Client,
                      whether buying, selling or investing in Real Estate. Our
                      CASAS Concierge is a seamless extension of CASAS Agents to
                      ensure every detail is handled efficiently and
                      professionally.
                    </p>
                  )}

                  <div className="relative mt-10">
                    <a
                      href="#contact"
                      className="btn hover:bg-strong-gray hover:border-orange-700 text-white rounded-md"
                      style={{ backgroundColor: "#e1dd20", border: "#e1dd20" }}
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End --> */}
        <ModalVideo
          channel="youtube"
          isOpen={this.state.videoModal}
          videoId="yba7hPeTSjk"
          onClose={() => this.setState({ videoModal: false })}
        />
      </React.Fragment>
    );
  }
}

export default About;
